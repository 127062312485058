import React from "react";

export const NavLinks = ({ open }) => {
  const links = [
    {
      link: "https://www.google.com/maps?sca_esv=0535bd5c4ba7e759&output=search&q=234+lofts+oba+elegushi+beach+rd+lekki&source=lnms&fbs=AEQNm0Aa4sjWe7Rqy32pFwRj0UkW1DRbm01j6DCVS0r1sTxn7h_rt6mVhwDmwtd3hPZjM8zl8B526v4C-56SyLN7G5Ea5Ep4L_RY1VmXS_R41aXeU5ajFjmBsr765NPF6RJN6O7tfdm4fYrQcx8JvkWAi9as7aqoMwAdZkNn5JDWRv-K1fkNdjxy4GNfx8XUG4Dt3D-yYzVE&entry=mc&ved=1t:200715&ictx=111",
      label: "get directions",
    },
  ];

  return (
    <ul
      className={
        open
          ? `flex flex-col gap-y-8 p-8 h-auto  absolute top-[4rem] bg-black w-full left-0 lg:hidden`
          : `lg:flex-row gap-4 lg:flex hidden`
      }
    >
      {links.map((l, index) => (
        <li
          className="text-white tracking-[0.065rem] lg:text-black"
          key={index}
        >
          <a href={l.link} target="_blank" rel="noreferrer">
            {l.label}
          </a>
        </li>
      ))}
    </ul>
  );
};
