import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CartItems from "../components/card/cardItems";
import { showModal, hideModal } from "../app/slices/modal";
import { useNavigate } from "react-router-dom";

import { PaymentForm } from "../components/form/form";
import { Logo } from "../assets";
import { CloseCircleOutlined } from "@ant-design/icons";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";

const Cart = () => {
  const modal = useSelector((state) => state.modal.modals);
  const cart = useSelector((state) => state.cart.carts);
  const totalPrice = useSelector((state) => state.cart.totalPrice);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const triggerModal = () => {
    dispatch(showModal());
  };

  let nairaCurrency = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
    currencyDisplay: "symbol",
  });

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Firenation Inc | Cart</title>
        <link rel="canonical" href="https://firenationinc.com" />
      </Helmet>
      <Layout>
        {cart?.length === 0 ? (
          <div className="mt-24 flex justify-center items-center flex-col gap-8">
            <p className="tracking-[0.065em]">No Cart</p>
            <button
              className="tracking-[0.065em] bg-secondary px-5 py-3 rounded-md
         shadow-md font-medium text-white"
              onClick={() => navigate("/")}
            >
              Go buy your tickets
            </button>
          </div>
        ) : (
          <div className="mt-24 flex justify-center flex-col gap-4 w-full items-center">
            <div className="grid w-full max-w-4xl bg-white place-items-center gap-4">
              {cart?.map((_) => (
                <CartItems
                  key={_.id}
                  id={_.id}
                  image={_.image}
                  // name={_.title}
                  price={
                    <>
                      <span>
                        Total Price : {nairaCurrency.format(totalPrice)}
                      </span>
                    </>
                  }
                  qty={
                    <>
                      <span>Total quantity : {_.quantity}</span>
                    </>
                  }
                />
              ))}
              <button
                onClick={() => triggerModal()}
                className="bg-primary text-white w-fit px-4 py-2 tracking-[0.065em] rounded-md"
              >
                Proceed to checkout
              </button>
            </div>
          </div>
        )}
      </Layout>
      {modal && (
        <>
          <div className="absolute inset-0 bg-black bg-opacity-30 w-full h-full top-0 min-h-full">
            <div className="w-full flex items-center justify-center">
              <div
                className={
                  modal
                    ? "bg-white border-2 relative top-24 rounded-md shadow w-full max-w-3xl grid place-items-center"
                    : ""
                }
              >
                <div className="flex w-full justify-center flex-col p-6 items-center gap-4">
                  <div className=" flex w-full justify-between  items-center">
                    <div className="bg-opacity-60 rounded-full">
                      <Logo width={50} />
                    </div>
                    <button
                      className="text-white text-2xl rounded-full leading-none p-1 flex items-center bg-secondary"
                      onClick={() => dispatch(hideModal())}
                    >
                      <CloseCircleOutlined width={28} height={28} />
                    </button>
                  </div>
                  <PaymentForm />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Cart;
