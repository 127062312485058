import React, { useEffect, useState } from "react";
import { usePaystackPayment } from "react-paystack";
import { useDispatch, useSelector } from "react-redux";
import { CustomInput } from "../../style/styled";
import { hideModal } from "../../app/slices/modal";
import emailjs from "@emailjs/browser";
import toast from "react-hot-toast";

emailjs.init({
  publicKey: "GMbZBdJQAsJUrwDRC",
});

export const PaymentForm = () => {
  const initialState = {
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
  };
  const totalPrice = useSelector((state) => state.cart.totalPrice);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [config, setConfig] = useState({
    reference: new Date().getTime().toString(),
    email: "",
    amount: totalPrice * 100,
    publicKey: "pk_live_cc8806ae4a750ae18fbaade5cbcd19d26ec8f49c",
    metadata: "",
    channels: ["card", "bank_transfer"],
    currency: "NGN",
  });

  useEffect(() => {
    setConfig((prevConfig) => ({
      ...prevConfig,
      email: formData.email,
      metadata: formData,
    }));
  }, [formData]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onSuccess = (reference) => {
    sendEmail(reference);
  };

  const sendEmail = async (reference) => {
    const barcodeUrl = `https://bwipjs-api.metafloor.com/?bcid=code128&text=${reference?.transaction}&scale=3&includetext`;

    setLoading(true);
    const templateParams = {
      from_name: "Firenation Inc",
      recipient_email: formData.email,
      contact_phone: formData.phone,
      to_name: formData.firstname + " " + formData.lastname,
      message: `
      Order in the bag!\n Hi ${
        formData.firstname + " " + formData.lastname
      }\n Here is your order receipt for payment of ${nairaCurrency.format(
        totalPrice
      )} with for the event\n Order Number ${
        reference?.reference
      }\n TRAP(Take Risk And Party)\n From 5pm August 31st, 2024 till fade\n Address of the party is at 234 Loft Oba Elegushi Beach Road, Lekki\n Please present the barcode below at the entry:\n <img src="${barcodeUrl}" alt="Barcode" />\n Thank you for your purchase!
      `,
    };

    await emailjs
      .send("service_pjln8fl", "template_7js1kjb", templateParams)
      .then((response) => {
        setLoading(false);
        toast("You would receive an email for confirmation of payment", {
          position: "top-right",
        });
        setFormData(initialState);
        dispatch(hideModal());
      })
      .catch((error) => {
        console.error("Failed to send email.", error);
      });
  };

  const onClose = () => {
    console.log("closed");
  };

  const initializePayment = usePaystackPayment(config);

  const onFinish = (e) => {
    e.preventDefault();
    setConfig((prevConfig) => ({
      ...prevConfig,
      email: formData.email,
      metadata: formData,
    }));
    initializePayment(onSuccess, onClose);
  };

  let nairaCurrency = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
    currencyDisplay: "symbol",
  });

  return (
    <div className="flex flex-col gap-4 w-full items-center justify-center">
      <div className="w-full">
        <form
          autoComplete="false"
          className="flex w-full flex-col gap-5"
          onSubmit={onFinish}
        >
          <div className="flex w-full items-center justify-center flex-col gap-y-6">
            <div className="flex gap-4 w-full">
              <CustomInput
                type="text"
                value={formData.firstname}
                onChange={handleChange}
                required={true}
                name="firstname"
                className="placeholder-black"
                placeholder="firstname"
              />

              <CustomInput
                type="text"
                value={formData.lastname}
                onChange={handleChange}
                name="lastname"
                className="placeholder-black"
                required={true}
                placeholder="lastname"
              />
            </div>
            <div className="flex gap-4 w-full">
              <CustomInput
                value={formData.phone}
                onChange={handleChange}
                required={true}
                placeholder="phone number"
                type="tel"
                className="placeholder-black"
                name="phone"
              />
            </div>
            <div className="flex gap-4 w-full">
              <CustomInput
                value={formData.email}
                onChange={handleChange}
                required={true}
                type="email"
                className="placeholder-black"
                placeholder="email"
                name="email"
              />
            </div>
            <div className="flex gap-4 w-full">
              <CustomInput
                type="text"
                disabled={true}
                className="placeholder-black"
                name="amount"
                value={nairaCurrency.format(totalPrice)}
              />
            </div>
          </div>
          <button
            className="px-5 py-3 text-white font-medium tracking-[0.065rem] rounded-md bg-[#53a606] w-fit"
            type="submit"
            disabled={loading}
          >
            pay with paystack
          </button>
        </form>
      </div>
    </div>
  );
};
